@import "normalize.css";
@import "./inter/inter.css";
@import "./node_modules/modularscale-sass/stylesheets/_modularscale.scss";

body {
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: ms(3) ms(3);
}

h1 {
  font-weight: 200;
  font-size: ms(2);
  line-height: ms(1);
}


h2 {
  font-weight: 600;
  font-size: ms(0);
  line-height: ms(1);
}

h3 {
  font-weight: 200;
  font-size: ms(1);
  line-height: ms(2);
}

a {
  color: black;
}

strong {
  font-weight: 600;
}

p, ol {
  line-height: ms(1);
}

.logo-height {
  display: block;
  height: ms(1);
}

img, .print-hidden {
  display: none;
}
